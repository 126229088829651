import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TitleText from "../components/text/title-text/title-text"

import styles from "./normativa-meco.module.scss"

function RegulationPage() {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "regulation-dog.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Normativa Meco" />
      <TitleText text="Normativa que afecta a los animales domésticos del municipio de Meco" />
      <div className={styles.content}>
        <p className={styles.paragraph}>
          No hay mejor manera de defender los derechos de los animales de
          compañía o de nosotros mismos como propietarios de mascotas, como
          conocer las distintas leyes que regulan su tenencia.
        </p>
        <p className={styles.paragraph}>
          Las distintas normativas que nos afectan emanan de las distintas
          Administraciones Públicas: Estatal, Autonómica y Municipal.
        </p>
        <p className={styles.paragraph}>
          En Meco, la Ordenanza Municipal de Tenencia y Protección de Animales
          fue aprobada el 12 de febrero de 2016 e incluye la creación de un
          censo genético de los perros mediante su identificación por ADN. El
          Ayuntamiento de Meco se encarga de la creación y gestión del censo
          genético y ofrece información en su{" "}
          <a href="http://www.ayto-meco.es/es-actualidad/noticias/119-perros-adn-meco-proteccion-convivencia">
            página web
          </a>
          .
        </p>
        <p className={styles.paragraph}>
          La{" "}
          <a href="https://www.bocm.es/boletin/CM_Orden_BOCM/2016/02/12/BOCM-20160212-81.PDF">
            Ordenanza Municipal
          </a>{" "}
          está publicada en el Boletín Oficial de la Comunidad de Madrid y es
          muy recomendable su lectura si eres propietario de animal doméstico.
        </p>
        <Img
          className={styles.image}
          fluid={data.placeholderImage.childImageSharp.fluid}
        />
        <p className={styles.paragraph}>
          En el ámbito supramunicipal otras legislaciones que afectan a los
          animales de compañía son la{" "}
          <a href="http://www.madrid.org/wleg_pub/servlet/Servidor?opcion=VerHtml&nmnorma=9425">
            Ley de Protección de los Animales Domésticos de la Comunidad de
            Madrid
          </a>{" "}
          y la{" "}
          <a href="https://www.boe.es/buscar/pdf/1999/BOE-A-1999-24419-consolidado.pdf">
            Ley sobre Animales Potencialmente Peligrosos
          </a>
          .
        </p>
      </div>
    </Layout>
  )
}

export default RegulationPage
